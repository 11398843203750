<template>
    <div class="field-filter d-flex no-arrow-input"  ref="searchContainer" :key="item.updated" v-bind="$attrs">
        <span class="field-filter-item d-flex ps-2" v-if="!hideColumn">
            [{{item.title}}]
        </span>
        <span class="field-filter-item  d-flex " v-if="!item.isBit && !item.dateExpression && item.applied && item.shortOperator">
            {{item.shortOperator}}
        </span>
    
        <input v-if="item.disabledTitle" disabled 
            class="text-truncate d-flex flex-1 w-25"
            :value="item.disabledTitle" 
            :title="item.label" >


        <ODatePicker v-else-if="item.isDate"
            v-model="selectedItemValue" 
            containerClass="dp-override text-truncate d-flex flex-1 w-25 "
            format = "Short Date"
            class="text-truncate rounded-0"
            :placeHolder="placeHolder"
            :clearable="false"
            clearableFromPicker
            :timePicker="false"/>
        
        <NumberEditor v-else-if="item.isNumber && item.options?.format && item.options?.format.indexOf('%') > -1"
            class="text-truncate d-flex flex-1 w-25"
            v-model="selectedItemValue"
            :title="item.label"
            :placeHolder="placeHolder"
            :format="item.options.format"
            @keyup.enter="applySearchEnter"/>

        <input v-else-if = "item.isNumber" type="number" 
            class="text-truncate d-flex flex-1 w-25"
            v-model="selectedItemValue"
            :title="item.label"
            :placeHolder="placeHolder"
            @keyup.enter="applySearchEnter">
        <DistinctAutocomplete v-else-if="!filterObject.disableAutoComplete && item.filterEditor === 'Distinct' && item.filterDropdown === 'OFilterDropdown' && !hideDropdown && !autocompleteDisabled" :filterObject="filterObject" :filterItem="item" :placeHolder="placeHolder"/>
        <input v-else  type="text" 
            class="text-truncate d-flex flex-1  w-25"
            v-model="selectedItemValue"
            :title="item.label"
            :placeHolder="placeHolder"
            @keyup.enter="applySearchEnter"
            :autofocus="autofocus"
            >
        
        
        <button @click="reset" v-if="item.selectedValue" :class="{'pe-0':!hideDropdown}" class="btn btn-link close text-decoration-none py-0 ps-2 d-flex " tabindex="-1">
            &times;
        </button>

        <o-dropdown placement="bottom-end" v-if="item.filterDropdown && !hideDropdown"  :popperOptions="popperOptions" ref="dropdown">
            <template #default="scope">
                <button class="btn btn-link btn-sm px-2 d-flex " :class="{'o365-active-filter': item.applied}" :ref="scope.target" @click="scope.open" style="padding-top:2px;" tabindex="-1">
                    <i class="bi bi-funnel-fill"></i>
                </button>
            </template>

            <template #dropdown="scope">
                <div class="card shadow rounded-0 o365-filter-dropdown bg-light-subtle" :ref="scope.container">
                    <div class="p-2"
                        :style="[{'width':Math.max(400,$refs.searchContainer.clientWidth-2)+'px'}]">

                        <component :is="item.filterDropdown" :hideOperators = "hideOperators" :filterItem="item" :filterObject="filterObject"
                            :dropdown="scope">
                        </component>
                    </div>
                </div>
            </template>
        </o-dropdown>
    </div>
</template>

<script>
    import OFilterDropdown from 'o365.vue.components.FilterDropdown.vue';
    export default {
        components: { OFilterDropdown }
    }
</script>

<script setup>
    import { defineProps, ref, defineAsyncComponent, computed, h } from 'vue';
    import ODropdown from 'o365.vue.components.DropDown.vue';
    import ODatePicker from 'o365.vue.components.DatePicker.vue';
    import DistinctAutocomplete from 'o365.vue.components.FilterEditor.DistinctAutocomplete.vue';
    import { default as useFieldFilterControls } from 'o365.vue.composables.fieldFilter.ts';
    const NumberEditor = defineAsyncComponent(async () => {
        const editors = await import('o365.vue.components.inputEditors.jsx');
        return editors.ONumberEditor;
    });

    // Use non async autocomplete component for now, need some sort of focus api for async components (now it fails to focus on lookup open)
    // const DistinctAutocomplete = defineAsyncComponent({
        // loader: () => import('o365.vue.components.FilterEditor.DistinctAutocomplete.vue'),
        // loadingComponent: () => h('div', { class: 'd-flex flex-1'}),
    // });

    const props = defineProps(['columnName', 'filterObject', 'searchOnEnter', 'inputClassName', 'placeHolder', 'hideColumn', 'hideDropdown','hideOperators','filterItem', 'autoSearchDebounce', 'autofocus']);
    const dropdown = ref("null");

    const { reset, applySearchEnter } = useFieldFilterControls(props.columnName, props.filterObject);

    const popperOptions = [{ name: 'offset', options: { offset: [-1, -1] } }];
    let item = props.filterItem??props.filterObject.getItem(props.columnName);
    const autocompleteDisabled = computed(() => item?.options?.filterParams?.autocomplete?.disable ?? false);

    const selectedItemValue = computed({
        get() {
            return item.selectedValue;
        },
        set(newValue) {
            item.selectedValue = newValue;
           // item.expressionValue = newValue;
            if (props.autoSearchDebounce != null && props.autoSearchDebounce !== false) { debounceSearch(); }
        }
    });

    let searchDebounce = null;
    function debounceSearch(e) {
        if(item.valueType == 'number') return;
        if (searchDebounce) { window.clearTimeout(searchDebounce); }
        searchDebounce = window.setTimeout(() => {
            applySearchEnter();
            searchDebounce = null;
        }, props.autoSearchDebounce);
    }

</script>

<style>
    :root,[data-bs-theme=light] {
        --o365-field-filter-border: var(--bs-gray-400);
    }

    [data-bs-theme=dark] {
        --o365-field-filter-border: var(--bs-light-border-subtle);
    }
   
    .field-filter {
       /* flex-wrap: wrap;*/
        align-items: stretch;
        border: 1px solid var(--o365-field-filter-border);
        width:100%;
        font-size: .875rem;
        background-color:var(--bs-light-bg-subtle);
    }

    .field-filter > span {
        padding: 0.25rem 0.15rem;
    }

    .field-filter input {
        padding: 0.25rem 0.25rem;
        font-size: .875rem;
        border:none;
        background-color:var(--bs-light-bg-subtle);
    }

    .field-filter input:focus {     
        outline-width: 0px;
        outline: none;
        background-color: rgb(var(--bs-body-bg-rgb));
    }

    .dp-override > div {
        width:100%;
    }
</style>
